import {React,useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

function Colorshow({ filterColor, colorList, handleColorSelect, activeColor, cid ,details}) {

  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredColours, setFilteredColours] = useState(colorList);

  useEffect(() => {
    if (!colorList?.length) {
      setFilteredColours([]);
    }

    if (!searchQuery?.length) {
      setFilteredColours(colorList || []);
    }

    setFilteredColours(
      colorList.filter((c) => {
        return c.colorcode.toLowerCase().includes(searchQuery.toLowerCase());
      })
    );
  }, [searchQuery, colorList]);

  let classColorMelange = document.getElementsByClassName('color-product-melange');
  let classColorNotMelange = document.getElementsByClassName('color-product-not-melange');
  let productName = details?.nome_articolo || details?.nome_articolo_page_product;

  
  if (productName && productName.includes('Melange')) { 
                
    Array.from(classColorNotMelange).forEach((element) => { //color-product-not-melange
      element.style.opacity = '0.3';
    });
    Array.from(classColorMelange).forEach((element) => { 
      element.style.opacity = '1';
    });
  }
  else{
    
    Array.from(classColorNotMelange).forEach((element) => { //color-product-not-melange
      element.style.opacity = '1';
    });
  
    Array.from(classColorMelange).forEach((element) => { 
      element.style.opacity = '0.3';
    });
  }

  return (
    <>
      {filterColor?.length != 0 && (
        <>
          {/*<div className='accordation_color_filter'> Colori standard</div>*/}
          <div className="color_section custom-scrollbar">
            {filterColor?.map((color, idx) => (
              
              <div
                key={color}
                className={`color_items ${color?.id == cid ? 'active_selecteed_color' : 'deactive_selecteed_color'}`}
                onClick={() =>
                  handleColorSelect(
                    `#${color?.hex?.replace('#', '')}`,
                    color?.inventcolorid,
                    color?.hex_image_url,
                    color.id
                  )
                }
              >
                <div className="color_img">
                  {color?.hex_image ? (
                    <img className="colorSchema" src={color.hex_image_url} />
                  ) : (
                    <div className="colorSchema" style={{ backgroundColor: `#${color?.hex?.replace('#', '')}` }}></div>
                  )}
                </div>
                <div className="color_info">
                  <span className="code">{color?.inventcolorid}</span>
                  <span className="desc">{color?.mel}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {/*<div className='accordation_color_filter'> Colori melange</div>*/}
      <div className="color_section custom-scrollbar">
        {colorList?.length > 0 ? (
            <div className="search-block">
              <input
                type="text"
                placeholder={t('Digita nome del colore')}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              <span></span>
            </div>
          ) : null}
          {filteredColours?.map((color, idx) => {
          return (
            <div
              key={color?.id}
              className={`color_items ${color?.id == cid ? 'active_selecteed_color' : 'deactive_selecteed_color',color?.mel ? 'color-product-melange' : 'color-product-not-melange'}`}
              onClick={() =>
                handleColorSelect(
                  `#${color?.hex?.replace('#', '')}`,
                  color?.inventcolorid,
                  color?.hex_image_url,
                  color.id
                )
              }
            >
              <div className="color_img">
                {color?.hex_image ? (
                  <img className="colorSchema" src={color.hex_image_url} />
                ) : (
                  <div className="colorSchema" style={{ backgroundColor: `#${color?.hex?.replace('#', '')}` }}></div>
                )}
              </div>
              <div className="color_info">
                <span className="code">{color?.inventcolorid}</span>
                <span className="desc">{color?.mel}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Colorshow;